.fxs-controls-grid-header {
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfb;
}

.fxs-controls-grid-header-paging {
  padding: 12px;
}

.fxs-controls-grid-norows {
  padding: 12px;
  background-color: lightgray;
}

.fxs-controls-grid {
  min-width: 100%;
  background-color: #fff;
  border-spacing: 1px 0;
}

.fxs-controls-grid-columnheader {
  background-color: #efefef;
  padding-right: 24px !important;
  cursor: pointer;
  font-weight: 700;
}

.fxs-controls-grid-columnheader-background-sortasc {
  background-image: url(../Images/sort_asc.png);
  background-repeat: no-repeat;
  background-position: center right;
}

.fxs-controls-grid-columnheader-background-sortdesc {
  background-image: url(../Images/sort_desc.png);
  background-repeat: no-repeat;
  background-position: center right;
}

.fxs-controls-grid-columnheader-background-unsorted {
  background-image: url(../Images/sort_both.png);
  background-repeat: no-repeat;
  background-position: center right;
}

.fxs-controls-grid-altrows .odd {
  background-color: #fff;
}

.fxs-controls-grid-altrows .even {
  background-color: #eee;
}

.fxs-controls-grid-cell {
  padding: 4px 8px;
}

.fxs-controls-grid-cell-wrapped>div {
  padding: 4px 8px;
  max-height: 200px;
  overflow-y: auto;
  text-overflow: ellipsis;
}

.fxs-controls-grid-cell-success {
  padding: 12px 16px;
  background-color: #dff0d8;
}

.fxs-controls-grid-cell-failure {
  padding: 12px 16px;
  background-color: #ffcfdf;
}

.fxs-controls-grid-cell-warning {
  padding: 12px 16px;
  background-color: #fde6bc;
}

.accordion:hover {
  background-color: #acbad4;
  color: #ffffff;
  cursor: pointer;
}

.fxs-controls-grid .accordion-open {
  background-color: #ddd;
  font-weight: bold;
}

tr.fold {
  display: none;
}

tr.fold.open {
  display: table-row;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.alignjustify {
  text-align: justify;
}

.aligntop {
  vertical-align: top;
}

.alignbottom {
  vertical-align: bottom;
}

table.bladeDetailsTable {
  float: right;
  margin: 5px 15px;
}

.bladeDetailsTable td {
  padding: 0px 15px
}

.bladeDetailsTableRow {
  color: blue;
  border-bottom: 1px solid black;
}

.historic-table {
  font-size: 16px;
}

.visual-select {
  padding-top: 4px;
}

.detail-header {
  text-align: center;
}