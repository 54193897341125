.fxs-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: #0078d4;
  color: #fff;
}

.fxs-topbar-left {
  display: flex;
  align-items: center;
}

.fxs-topbar-title {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fxs-topbar-home {
  flex: 0 0 auto;
  font-size: 15px;
  padding: 0 15px;
  margin: 0;
}

.fxs-topbar-home a {
  color: #fff;
  text-decoration: none;
}

span.fxs-topbar-username {
  padding: 9px;
  margin: 0;
  cursor: pointer;
  font-size: 15px;
}

span.fxs-topbar-username:hover {
  background-color: #106ebe;
}

span.fxs-topbar-cloud {
  padding: 9px;
  margin: 0;
  cursor: pointer;
  font-size: 15px;
}

span.fxs-topbar-cloud:hover {
  background-color: #106ebe;
}

.fxs-topbar-right {
  display: flex;
}

.fxs-topbar-reportbuglink {
  background-color: #ff8c00 !important;
  display: flex;
  height: 40px;
  width: auto;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 13px;
  color: #000;
  text-decoration: none;
}

.fxs-topbar-signout {
  position: absolute;
  display: none;
  right: 0;
  top: 40px;
  color: #000;
  border: none;
}

.fxs-topbar-signout:hover {
  text-decoration: underline;
}

.fxs-topbar-signout-visible {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  padding: 8px;
  margin: 0;
  min-width: 168px;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, .3);
}

.fxs-topbar-signout-visible:hover {
  background-color: rgba(85, 179, 255, .1);
}

.fxs-controls-cloudswitcher {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.fxs-controls-cloudswitcher-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: calc(100% - 80px);
  max-height: calc(100% - 160px);
}

.fxs-controls-cloudswitcher-content-closebutton {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 8px;
  background: none;
  border: 0;
}

.fxs-controls-cloudswitcher-content-closebutton:hover {
  background: red;
}

.fxs-controls-cloudswitcher-content-closebutton svg {
  height: 12px;
  width: 12px;
}

.fxs-controls-cloudswitcher-content-page {
  overflow-y: auto;
  margin: 20px;
  max-width: 400px;
}

.fxs-controls-cloudswitcher-content-page ul {
  list-style-type: disc;
  margin-left: 20px;
}

.fxs-controls-cloudswitcher-content-page>* {
  margin-bottom: 10px;
}

.fxs-controls-cloudswitcher-currentcloud {
  font-weight: bold;
}