.fxs-sidebar {
  position: relative;
  flex-shrink: 0;
  background-color: #f9f9f9;
}

.fxs-sidebar-collapsed {
  width: 48px;
}

.fxs-sidebar-item .fxs-sidebar-item-text {
  padding-left: 14px;
}

.fxs-sidebar-item:hover {
  background-color: rgba(85, 179, 255, .1);
}

.fxs-sidebar-expander-container {
  border-bottom: 1px solid #c7c7c7;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 9px 0 8px;
}

.fxs-sidebar-shadow {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  background-color: red;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, .06) 0, rgba(0, 0, 0, 0) 100%);
}

.fxs-sidebar-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.fxs-sidebar-item a {
  display: flex;
  color: #1f1f1f;
  text-decoration: none;
  padding: 8px 14px;
}

.fxs-sidebar .fxs-sidebar-item-icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.fxs-sidebar-collapsed .fxs-sidebar {
  width: 48px;
}

.fxs-sidebar-collapsed .fxs-sidebar-item-text {
  display: none;
}

.fxs-sidebar-item-innerlist {
  padding-left: 30px;
}