.error-details {
    white-space: pre-wrap;
    text-align: justify;
    background-color: #f7f7f7;
    border-right: 1px solid #ececec;
    padding: 20;
}
.error-summary {
    vertical-align: center;
}
