@keyframes rotate-fully {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes delayed-appearence {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 90%;
    }
    100% {
        opacity: 1;
    }
}

.fxs-splashscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    -webkit-app-region: drag;
}

.fxs-splashscreen > .fxs-splashscreen-outer-ring {
    height: 60px;
}

.fxs-splashscreen > .fxs-splashscreen-mid-ring {
    flex-grow: 1;
}

.fxs-splashscreen > .fxs-splashscreen-inner-ring {
    height: 50px;
}

.fxs-splashscreen > div {
    display: flex;
    justify-content: center;
}

.fxs-splashscreen > .fxs-splashscreen-logo {
    height: 180px;
}

.fxs-splashscreen > .fxs-splashscreen-logo > svg {
    width: 150px;
    height: 150px;
    fill: #0078d4;
}

.fxs-splashscreen > .fxs-splashscreen-loader-container {
    animation: delayed-appearence 0.5s 1.5s linear 1 forwards;
    opacity: 0;
}

.fxs-splashscreen > .fxs-splashscreen-loader-container > .fxs-fabric-loading-spinner {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #0078d4 #c7e0f4 #c7e0f4;
    border-image: initial;
    animation: rotate-fully 1.3s cubic-bezier(0.53, 0.21, 0.29, 0.67) infinite;
}

.fxs-splashscreen > .fxs-msftlogo > svg {
    margin-bottom: 36px;
    width: 99px;
    height: 22px;
}

@media (prefers-color-scheme: dark) {
    .fxs-splashscreen {
        background-color: #333;
    }
}

@media screen and (-ms-high-contrast: active) {
    .fxs-splashscreen {
        -ms-high-contrast-adjust: auto;
        background-color: window;
    }
    .fxs-splashscreen .fxs-splashscreen-logo > svg {
        fill: windowText;
    }
    .fxs-splashscreen-loader-container > div::after {
        border-color: windowText;
        background: windowText;
    }
}