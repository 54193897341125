/**
 * CSS reset
 */

html {
  height: 100%;
  background-color: #fff;
}

h2.fxs-table-header {
  margin: 16px;
  font-size: 20px;
}

body {
  height: 100%;
  margin: 0;
  font-family: az_ea_font, wf_segoe-ui_normal, "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif !important;
  font-size: 13px;
  line-height: inherit !important;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

select[disabled] {
  color: #7f7f7f;
}

/**
 * Layout
 */

#root {
  height: 100%;
}

.fxs-portal {
  height: 100%;
  min-width: 350px;
  min-height: 200px;
}

.fxs-body-container {
  height: calc(100% - 40px);
  display: flex;
}

.fxs-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.fxs-topbanner {
  background-color: #fff483;
  margin: -12px -20px 0;
  padding: 8px;
}

.fxs-header {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.fxs-header-tab {}

.fxs-header-tab:hover {
  background-color: #e3e3e3;
}

.fxs-header-tab a {
  text-decoration: none;
  color: #015cda;
  padding: 8px;
  display: inline-block;
}

.fxs-header-tab-selected {
  border-bottom: 2px solid #9f9f9f;
}

.fxs-header-tab-selected:hover {
  background-color: initial;
}

.fxs-header-tab-selected a {
  color: #000;
  cursor: default;
}

.fxs-header-tab-selected a:hover {
  background-color: initial;
}

.fxs-banner {
  display: flex;
  align-items: center;
  background: lightblue;
  padding: 8px;
}

.fxs-banner-icon {
  max-height: 24px;
  max-width: 24px;
}

.fxs-banner-icon svg {
  max-height: 24px;
  max-width: 24px;
}

.fxs-banner-bannertext {
  margin-left: 8px;
  font-size: 14px;
}

.fxs-content {
  position: relative;
  height: calc(100% - 37px);
  overflow: auto;
  font-size: 12px;
}

.fxs-link-badge {
  position: absolute;
  height: 16px;
  right: 0;
  top: 0;
  width: 14px;
  fill: #0078d4;
}

/**
 * Overview
 */

.fxs-overview-section {
  margin: 12px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .15);
}

.fxs-overview-section-title {
  margin: 0;
  padding: 12px;
}

.fxs-overview-section-propertylist {}

.fxs-overview-section-property {
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  border: 0 solid lightgray;
  border-top-width: 1px;
}

.fxs-overview-section-property-label {
  display: flex;
  justify-content: center;
  margin-right: 16px;
  max-width: 300px;
}

.fxs-overview-section-property-label span {
  margin-right: 4px;
}

.fxs-overview-section-property-value {
  display: flex;
  justify-content: center;
  font-weight: bold;
  max-width: 300px;
}

.fxs-overview-section-property-value span {
  margin-left: 4px;
}

/**
* Helpers
*/

.fxs-tab-section {
  padding: 8px;
}

.fxs-tab-section-field {
  display: flex;
  align-items: center;
  padding: 8px;
}

.fxs-tab-section-field:first-child {
  padding-left: 0;
}

.fxs-tab-section-field:last-child {
  padding-right: 0;
}

.fxs-tab-section-field-label {
  margin-right: 8px;
}

.fxs-tab-section-field-value:not(input):not(select) {
  font-weight: bold;
}

.fxs-nodataoraccess-banner {
  background-color: #fffa5a;
  padding: 8px 16px;
}

/**
* Helpers
*/

.fxs-wrapitems {
  display: flex;
  flex-wrap: wrap;
}

.fxs-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fxs-button-link {
  background: none;
  border: none;
  padding: 0;
  color: #000;
}

.fxs-button-link:enabled {
  color: #0078d4;
  text-decoration: underline;
  cursor: pointer;
}

/**
* JQuery grid
*/

.fxs-portal .dataTables_wrapper .dataTables_info {
  width: 100%;
  text-align: center;
  padding-bottom: 0.755em;
}

.fxs-portal .dataTables_wrapper .dataTables_filter {
  text-align: left;
  float: initial;
  padding: 16px;
}

.fxs-portal .dataTables_wrapper .dataTables_filter input {
  width: calc(100% - 60px);
}

/**
* Bootstrap reset
*/

.fxs-sidebar-item a {
  box-sizing: initial !important;
}

svg {
  box-sizing: initial !important;
}

/**
* Colors 
*/

.green {
  background-color: lightgreen;
}

.lightgreen {
  background-color: #b6ffb6;
}

.lightyellow {
  background-color: #ffffe0;
}

.yellow {
  background-color: #ffffb0;
}

.orange {
  background-color: lightsalmon;
}

.red {
  background-color: lightcoral;
}

/**
* Icons
*/

.msportalfx-svg-rotate360 {
  -webkit-animation: ImageRotation 1.45s infinite linear;
  animation: ImageRotation 1.45s infinite linear;
}

@-webkit-keyframes ImageRotation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ImageRotation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.sticky-summary-fixed {
  position: fixed;
  top: 40px;
  background-color: white;
  z-index: 1;
}